import React from 'react'
import ReduxToastr from 'react-redux-toastr'
import { useSelector } from 'react-redux'

const Toastr = () => {
  const toastrState = useSelector(state => state.toastr)

  return (
    <ReduxToastr
      timeOut={toastrState.toastrs[0]?.type == 'success' ? 1000 : 5000} // Dynamically change timeout
      position='top-right'
      transitionIn='fadeIn'
      transitionOut='fadeOut'
      progressBar
      preventDuplicates
      newestOnTop={false}
    />
  )
}

export default Toastr
